<template>
  <v-container>
    <v-row >
      <v-col cols="12">
        <v-card>
          <v-toolbar align-center color="color1 color1Text--text">
            <v-toolbar-title align-center><h1>Terms</h1></v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <h1>VolleyballLife Terms Of Service</h1>

            <p>
              The following terms and conditions ("Terms of Service") govern your access to, and use of, the VolleyballLife.com website (the "Service") operated by Mohawk Man Technologies, Inc., dba VolleyballLife ("VolleyballLife").
            </p>
            <p>
              By clicking on the "I Agree" checkbox or by accessing, browsing, or otherwise using the site, you agree to be bound by this terms of service and any of the related policies or guidelines, including any subsequent changes or modifications to them. If you do not agree to this terms of service or any changes, do not access or otherwise continue to use this site.
            </p>

            <h2>Other Applicable Terms</h2>
            <p>
              Privacy. Refer to VolleyballLife's Privacy Policy to understand how VolleyballLife collects, uses and discloses your personal information. The Service is not directed to, and not intended for the use of, children under the age of 13. If you are under the age of 13, please do not register or submit any personal information to the Service.
            </p>
            <p>
              Conduct Policy. You agree to the terms of VolleyballLife's Conduct Policy related to your use of the Service, including your submission of any data, text, photographs, graphics, messages, ratings, forum postings, comments or other materials (collectively, "User Content"). The Conduct Policy is located at the end of, and is a part of, this Terms of Service.
            </p>
            <p>
              Payments Service. If you are a VolleyballLife user, coach or tournament director, you agree to the terms ofVolleyballLife's Payments Service Acceptable Use Policy. The policy is located at the end of, and is a part of, these Terms of Service.
            </p>

            <h2>Copyright and License</h2>
            <p>
              All copyrighted and copyrightable materials on the Service, including but not limited to the text, design, product information, graphics, images, pictures, sound and other files, and the selection, compilation and arrangement thereof (collectively, "Materials") are ALL RIGHTS RESERVED Copyright © 2017 Mohawk Man Technologies, Inc. and/or its licensors.
            </p>
            <p>
              VolleyballLife grants you a limited license to make personal use of the Service to access and review the Materials for your information purposes only. This license does not include the right to: (a) use the Service or Materials for other than for its intended purpose, including but not limited to any marketing, selling, or other commercial uses; (b) use framing techniques to enclose any portion of the Service, including any images found on the Service or any text or the layout or design of any page or form contained on a page; (c) publish, publicly perform or display, or distribute to any third party any Materials, including reproduction or hosting the Materials on any computer network or broadcast or publications media; (d) make derivative uses of the Service or the Materials; or (e) any systematic collection or extraction of data through the use of any data mining, robots or similar data gathering or extraction methods.
            </p>
            <p>
              You are not conveyed any other right or license, by implication, estoppel or otherwise, in or under any patent, trademark, or proprietary right of VolleyballLife or any third party. Any unauthorized use of the Service will terminate the permission or license granted by VolleyballLife to you under this Terms of Service and may violate applicable law, including but not limited to copyright laws, trademark laws (including trade dress), and communications regulations and statutes.
            </p>

            <h2>Your Liability</h2>
            <p>
              You are solely responsible for your activities on our Service, including all content that you submit or a third party submits on your behalf or using your account. You agree to indemnify VolleyballLife and its shareholders, directors, officers, employees, agents, successors and assigns against any and all third party claims, actions, demands, suits and all related losses, liabilities, damages, penalties, costs and expenses (including, but not limited to, reasonable attorneys' fees) incurred by an indemnified party arising out of or related to: (a) any violation of law or regulation from your use of the Service, (b) any actual or alleged breach by you of any obligations, representations, warranties under this Terms of Service, including violations of our Conduct Policy; and (c) any actual or alleged infringement or misappropriation of the intellectual property rights of any third party by all User Content that you submit or a third party submits on your behalf or using your account.
            </p>
            <p>
              Furthermore, VolleyballLife takes no responsibility for any damages, injuries or other consquences that occur from you using VolleyballLife content (e.g., skills and drills videos).  These videos are there for informational purposes only and you and you alone must decide whether you can safely perform the techniques demonstrated.  Many athletic or other active endeavors or hobbies, including those which may be described or shown in the VolleyballLife content, should only be performed under the direction of qualified professionals and using appropriate safety equipment and personnel.  You accept all responsibility and release VolleyballLife from any liability arising out of or relating to your use of such content.
            </p>

            <h2>Trademarks and Service Marks</h2>
            <p>
              "VolleyballLife," "VolleyballLife.com" and VolleyballLife's logos are trademarks, service marks or registered trademarks of Mohawk Man Technologies, Inc. or its suppliers and licensors, and may not be copied, used or imitated, in whole or in part, without the prior written permission of VolleyballLife or its suppliers or licensors. You may not use meta tags or any other "hidden text" using any of the above-referenced marks without VolleyballLife's permission. Additionally, all page headers, graphics, icons, and scripts are service marks, trademarks, and/or trade dress of VolleyballLife, and may not be copied, imitated, or used, in whole or in part, without VolleyballLife's prior written permission. All other VolleyballLife names or logos mentioned on the Service, or any other trademarks, registered or otherwise, are the property of their respective owners. Reference to any products, services, processes or other information, by trade name, trademark, manufacturer, supplier or otherwise does not constitute or imply endorsement, sponsorship or recommendation thereof by VolleyballLife.
            </p>

            <h2>Links and Third Party Content</h2>
            <p>
              VolleyballLife or third parties may provide links on the Service to other sites or content. VolleyballLife has no control over such sites or content, and therefore makes no claim or representation regarding, and expressly disclaims responsibility for, the quality, content, nature or reliability of sites or content linked to by the Service. VolleyballLife provides links to you only as a convenience, and the inclusion of any link on the Service does not imply our affiliation, endorsement, or adoption of the linked site or any information therein. When you leave the Service, our terms and policies no longer govern. You should review applicable terms and policies, including the privacy and data gathering practices, of any third-party sites.
            </p>

            <h2>No Warranties</h2>
            <p>
              The site and the materials contained therein are provided on an "as is" and "as available" basis without warranties of any kind, express or implied. You expressly agree that use of the site, including all content or data distributed by or downloaded or accessed from or through the site, is at your sole risk. VolleyballLife disclaims all warranties, express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, title and non-infringement as to the information, materials, and content on the site. VolleyballLife does not represent or warrant that materials in the site are accurate, complete, current, reliable or error-free.
            </p>
            <p>
              VolleyballLife is not responsible for typographical errors or omissions relating to pricing, text or photography. We cannot and do not represent or warrant that the site or its server(s) are free of viruses or other harmful components, including content that is posted by third parties. You should use industry-recognized software to detect and disinfect viruses from any download.
            </p>

            <h2>Limited Liability</h2>
            <p>
              In no event shall we be liable for any indirect, special, incidental, consequential or punitive damages (including but not limited to loss of use, loss of profits, or loss of data) whether in an action in contract, tort (including but not limited to negligence), equity or otherwise, arising out of or in any way connected with the use of or inability to use this site or the materials therein or resulting from unauthorized access to or alteration of data.
            </p>
            <p>
              You agree that VolleyballLife's aggregate liability for any actual and direct damages hereunder shall not exceed the amounts you paid to VolleyballLife during the prior 12 month period or $100. You acknowledge that this is a reasonable allocation of risk for your use of the site.
            </p>
            <p>
              Some jurisdictions do not allow the limitation or exclusion of certain warranties, conditions or damages, so some of the above exclusions may not apply to you.
            </p>

            <h2>Applicable Law and Venue</h2>
            <p>
              This Terms of Service and any transactions on this Service shall be governed by and construed in accordance with the laws of the State of California applicable to agreements made and entirely to be performed within the State of California, without resort to its conflict of law provisions. You agree that any action at law or in equity arising out of or relating to these terms and conditions shall be filed only in state or federal court located in San Diego, California, and you hereby irrevocably and unconditionally consent and submit to the exclusive jurisdiction of such courts over any suit, action or proceeding arising out of this Terms of Service.
            </p>

            <h2>Changes to This Terms of Service</h2>
            <p>
              VolleyballLife reserves the right to change or modify any of the terms and conditions contained in this Terms of Service, or any policy of the Service, at any time and in its sole discretion. If VolleyballLife decides to change this Terms of Service, it will post a new version on the Service and update the effective date set forth above. Any changes or modifications to this Terms of Service or policy will be effective upon posting of the revisions. Your continued use of the Service following posting of any changes or modifications constitutes your acceptance of such changes or modifications. You should frequently review this Terms of Service and any other applicable policies, including their dates, to understand the terms and conditions that apply.
            </p>

            <h2>Conduct Policy</h2>
            <p>
              Please read this Conduct Policy carefully. It governs your conduct while using various services on this Web site, located at VolleyballLife.com (the "Service") and operated by Mohawk Man Technologies, Inc., dba VolleyballLife ("VolleyballLife"). By using our Service, you agree to be bound the terms of this Conduct Policy.
            </p>
            <p>
              Any violation of this policy may result in the suspension or termination of your access to the site or use of our service and such action as we deem appropriate. Indirect or attempted violations of this policy, and actual or attempted violations by a third party on your behalf, shall be considered violations of the policy by you.
            </p>

            <h2>Prohibited Conduct</h2>
            <p>
              The following non-exhaustive list describes the kinds of illegal or harmful conduct that are prohibited on our Service.
            </p>
            <ul>
              <li>
                You agree not to upload, post or otherwise transmit any data that is unlawful, harmful, threatening, abusive, harassing, tortuous, defamatory, vulgar, obscene, libelous, known to be false and presented as truth, invasive of another's privacy, hateful, or racially, ethnically or otherwise objectionable.
              </li>
              <li>
                You agree not to upload, post or otherwise transmit any data that you do not have a right to transmit under any law or under contractual or fiduciary relationships (such as, but not limited to, inside information, proprietary and confidential information learned or disclosed as part of employment relationships or under non disclosure Codes).
              </li>
              <li>
                You agree not to upload, post or otherwise transmit any data that contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment.
              </li>
              <li>
                You agree not to upload, post or otherwise transmit any data that potentially infringes any patent, trademark, trade secret, copyright or other proprietary rights of any person or entity;
              </li>
              <li>
                You agree not to upload, post or otherwise transmit any data that is unsolicited or unauthorized advertising, promotional materials, "junk mail," "spam," "chain letters," "pyramid schemes," or any other form of solicitation.
              </li>
              <li>
                You agree not to use an account name, file or directory name, or third level domain (i.e., aaa.VolleyballLife.com), or other identifier that infringes or misappropriates any trademark, service mark or other indicia of origin or that is otherwise likely to cause confusion as to the source, affiliation, sponsorship or endorsement of a third party's products or services.
              </li>
              <li>
                You agree not to attempt to disguise the origin of any User Content transmitted to the Service.
              </li>
              <li>
                You agree not to act in any manner that negatively affects other users' ability to use the Service.
              </li>
              <li>
                You agree not to impersonate any person or entity, including, without limitation, a manufacturer or owner of any product, or falsely state or otherwise misrepresent your affiliation with a person or entity.
              </li>
              <li>
                You agree not to interfere with the Service, or servers or networks connected to the Service, or disobey any requirements, procedures, policies or regulations of networks connected to the Service.
              </li>
              <li>
                You agree not to post content that would be harmful to minors in any manner.
              </li>
              <li>
                You agree not to intentionally create and/or log in with multiple accounts.
              </li>
            </ul>

            <h2>System and Network Security</h2>
            <p>
              You are prohibited from violating the security of any system or network comprising the Service. Such violations may result in criminal and civil liability. Examples of system or network security violations include, without limitation, the following:
            </p>
            <ul>
              <li>
                Unauthorized access to or use of data, systems or networks, including any attempt to probe, scan or test the vulnerability of the Service or to breach security or authentication measures.
              </li>
              <li>
                Unauthorized monitoring of data or traffic on the Service.
              </li>
              <li>
                Interference with the Service including, without limitation, any type of flooding technique or deliberate attempt to overload a system such as denial of service attacks.
              </li>
              <li>
                Forging of any packet header, e-mail header or any part of a message header. This prohibition does not include the use of aliases or anonymous remailers.
              </li>
              <li>
                Using manual or electronic means to avoid any use or access limitations placed on this Service.
              </li>
            </ul>

            <h2>Enforcement</h2>
            <p>
              Notwithstanding anything to the contrary in this Conduct Policy, VolleyballLife reserves the right, but does not assume the obligation, to investigate any violation of this Conduct Policy or any other misuse of the Service or its systems, equipment or network. VolleyballLife further reserves the right (each in its sole discretion) to screen, edit, modify, remove or disable access to any content that violates these provisions or that VolleyballLife deems objectionable. Without imitating the foregoing, and notwithstanding anything contrary in our Privacy Policy, VolleyballLife reserves the right to report any activity, data or persons (including the disclosure of relevant user information) to appropriate law enforcement officials, regulators, or other appropriate third parties if VolleyballLife suspects you have violated this Conduct Policy or any law or regulation. VolleyballLife also may cooperate with appropriate law enforcement agencies to assist in the investigation and prosecution of any illegal conduct.
            </p>
            <p>
              If you want to report any violations of this Conduct Policy, please contact us at: admin@VolleyballLife.com.
            </p>

            <h2>General Terms; Repeater Infringer Policy</h2>
            <p>
              We reserve the right, without notice and in our sole discretion, to terminate your account and your license to use the Service and to block or prevent future access to and use of the Service. Without limiting the foregoing, and in accordance with the Digital Millennium Copyright Act (DMCA), it is VolleyballLife's policy to terminate, in appropriate circumstances as determined in our sole discretion, subscribers or account holders who we determine are repeat infringers. If any provision of this Terms of Service shall be deemed unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from this Terms of Service and shall not affect the validity and enforceability of any remaining provisions. If you believe your copyright has been violated by material uploaded by a user of VolleyballLife, please click here.
            </p>

            <h2>VolleyballLife Payments Service Acceptable Use Policy</h2>
            <p>
              You are independently responsible for complying with all applicable laws in all of your actions related to your use of VolleyballLife's Payment service, regardless of the purpose of the use. In addition, you must adhere to the terms of this Acceptable Use Policy.
            </p>

            <h2>Prohibited Activities</h2>
            <p>
              <strong>You may not use the VolleyballLife Payments service for activities that:</strong>
            </p>
            <ul>
              <li>
                violate any law, statute, ordinance or regulation.
              </li>
              <li>
                relate to sales of (a) narcotics, steroids, certain controlled substances or other products that present a risk to consumer safety, (b) drug paraphernalia, (c) items that encourage, promote, facilitate or instruct others to engage in illegal activity, (d) items that promote hate, violence, racial intolerance, or the financial exploitation of a crime, (e) items that are considered obscene, (f) items that infringe or violate any copyright, trademark, right of publicity or privacy or any other proprietary right under the laws of any jurisdiction, (g) certain sexually oriented materials or services, (h) ammunition, firearms, or certain firearm parts or accessories, or (i) certain weapons or knives regulated under applicable law.
              </li>
              <li>
                relate to transactions that (a) show the personal information of third parties in violation of applicable law, (b) support pyramid or ponzi schemes, matrix programs, other "get rich quick" schemes or certain multi-level marketing programs, (c) are associated with purchases of real property, annuities or lottery contracts, lay-away systems, off-shore banking or transactions to finance or refinance debts funded by a credit card, (d) are for the sale of certain items before the seller has control or possession of the item, (e) are by payment processors to collect payments on behalf of merchants, (f) are associated with the following Money Service Business Activities: the sale of traveler's cheques or money orders, currency exchanges or cheque cashing, or (g) provide certain credit repair or debt settlement services.
              </li>
              <li>
                involve the sales of products or services identified by government agencies to have a high likelihood of being fraudulent.
              </li>
              <li>
                violate applicable laws or industry regulations regarding the sale of (a) tobacco products, or (b) prescription drugs and devices.
              </li>
              <li>
                involve gambling, gaming and/or any other activity with an entry fee and a prize, including, but not limited to casino games, sports betting, horse or greyhound racing, lottery tickets, other ventures that facilitate gambling, games of skill (whether or not it is legally defined as a lottery) and sweepstakes unless the operator has obtained prior approval from PayPal and the operator and customers are located exclusively in jurisdictions where such activities are permitted by law.
              </li>
            </ul>

            <h2>Activities Requiring Approval</h2>
            <p>
              PayPal or Stripe require pre-approval to accept payments for certain services, as set forth in the PayPal or Stripe acceptable use policy, which you may access as of the date of this policy at the following links: https://www.paypal.com/us/webapps/mpp/ua/acceptableuse-full ; https://stripe.com/us/prohibited-businesses .
            </p>

            <h2>More Information</h2>
            <p>
              To learn more about the Acceptable Use Policy, please refer to admin@VolleyballLife.com.
            </p>

            <h2>Violations of the Acceptable Use Policy</h2>
            <p>
              We encourage you to report violations of this Acceptable Use Policy to VolleyballLife immediately. If you have a question about whether a type of transaction may violate the Acceptable Use Policy, you can email VolleyballLife at admin@VolleyballLife.com.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {

}
</script>

<style scoped>
ul {
  margin: 10px 0px 10px 40px;
}
</style>
